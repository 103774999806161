<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" class="form-control" placeholder="Username" autocomplete="username email"  v-model='loginData.username' v-on:keyup.enter="login(loginData)"/>
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="password" class="form-control" placeholder="Password" autocomplete="current-password" v-model='loginData.password' v-on:keyup.enter="login(loginData)"/>
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button variant="primary" class="px-4" @click="login(loginData)" >Login</b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });

  export default {
    name: 'Login',
    data() {
      return {
        loginData: {}
      };
    },
    components: {},
    created: function () {
    },
    methods: {
        keyPress(e){
            if(e.keyCode === 13)
            {
                this.login(this.loginData);
            }
        },
      login(data) {
        if (!this.$root.isNull(data) && !this.$root.isNull(data.username) && !this.$root.isNull(data.password)) {
          var payload = {
            data:data,
            success: response => {
              this.$store.state.loginData = response.data;
              this.$router.push({ path: 'ContentBlocks/Home' })
            },
            error: error => { miniToastr['error'](error, 'Error', 1000, null)}
          }
          this.$store.dispatch('login', payload);


        }
     },
    }
  }

</script>
